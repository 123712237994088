<template>
  <v-dialog v-model="dialog" :max-width="maxWidth" :width="maxWidth">
    <v-snackbar v-model="copyEmail" :timeout="3000" color="success" top right>
      Email copied successfully
    </v-snackbar>
    <v-snackbar v-model="noGroup" :timeout="3000" color="error" top right>
      Clinic {{ user.clinic }} does not have any registered support group!
    </v-snackbar>
    <v-stepper v-if="tos" v-model="e1">
      <v-stepper-header>
        <v-stepper-step
          v-if="!newSendMessage"
          editable
          :complete="e1 > 1"
          step="1"
        >
          Terms of Service
        </v-stepper-step>
        <v-stepper-step
          v-if="newSendMessage"
          editable
          :complete="e1 > 1"
          step="1"
        >
          New Message Cryo Team
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          v-if="!newSendMessage"
          editable
          :complete="e1 > 2"
          step="2"
        >
          Payment Method
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step v-if="!newSendMessage" editable step="3">
          Make Payment
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card
            class="mb-12 px-4 py-4"
            color="grey lighten-3 elevation-0 grey--text text--darken-2"
            :height="heightCustom"
            v-if="!newSendMessage"
            :style="styleCustomFontSizeText"
          >
            By continuing, I allow Prelude Cryopreservation Services, LLC to
            store my card and payment method for future use.
            <br />
            <br />
            <strong>Cryo Storage Refund Policy:</strong>
            All storage payments are non-refundable once processed. If you would
            like to cancel your payment, discontinue storage, change your method
            of payment or setup a payment plan you must confirm this change to
            your account before your payment has been processed.
          </v-card>

          <v-card
            class="mb-12 px-4 py-4"
            color="grey lighten-3 elevation-0 grey--text text--darken-2"
            height="450px"
            v-if="newSendMessage"
          >
            <NewMessageForm
              @onBack="closeMessage"
              @finished="closeMessage"
              isCryoMessage="true"
              groupId="234"
            />
          </v-card>
          <div
            class="mt-4"
            v-if="!newSendMessage"
            style="border-top: 2px solid gray; margin-top: 5px"
          >
            <v-row style="margin-top: 5px">
              <v-col
                cols="12"
                style="display: flex; justify-content: space-around"
              >
                <v-btn
                  color="primary"
                  :style="styleCustomButton"
                  @click="e1 = 2"
                >
                  Continue
                </v-btn>

                <v-btn
                  color="error"
                  :style="styleCustomButton"
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>

                <v-btn
                  :color="color"
                  :style="styleCustomButtonMessage"
                  class="white--text"
                  @click="newMessage"
                >
                  Send Message
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-select
              v-if="!hiddenSelectCard && cards && cards.length > 0"
              label="Choose Card"
              v-model="card"
              return-object
              prepend-icon="mdi-credit-card"
              :items="cards"
              :rules="cardRules"
            >
              <template v-slot:item="{ item, on }">
                <v-list-item v-on="on">
                  <v-col cols="10">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.description }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.account }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{ item.expiry.substr(0, 2) }}/{{
                          item.expiry.substr(2)
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-col>
                  <v-col cols="2">
                    <v-list-item-title v-if="item.is_default">
                      Default Card
                    </v-list-item-title>
                  </v-col>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                {{ item.description }} {{ item.account }}
              </template>
            </v-select>

            <div class="mt-4" style="display: flex; justify-content: flex-end">
              <v-btn
                :color="color"
                v-if="card"
                class="white--text"
                :style="styleCustomFontSize"
                @click="newCreditCard"
              >
                Add New Credit Card
              </v-btn>
            </div>

            <v-card
              v-if="!card"
              class="mb-12 px-4 py-2"
              color="grey lighten-3 elevation-0 grey--text text--darken-2"
            >
              <div v-if="checkMobile">
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Card Number"
                      v-model="account"
                      :rules="[...inputRule, ...accountRule, ...onlyNumsRule]"
                      validate-on-blur
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Card CVV"
                      v-model="cvv"
                      :rules="[...inputRule, ...cvvRule, ...onlyNumsRule]"
                      validate-on-blur
                    />
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Card Number"
                      v-model="account"
                      :rules="[...inputRule, ...accountRule, ...onlyNumsRule]"
                      validate-on-blur
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Card CVV"
                      v-model="cvv"
                      :rules="[...inputRule, ...cvvRule, ...onlyNumsRule]"
                      validate-on-blur
                    />
                  </v-col>
                </v-row>
              </div>
              <CardExpiration v-model="expiry" :rules="inputRule" />
              <v-text-field
                label="Zip Code"
                v-model="zipcode"
                :rules="[...inputRule, ...onlyNumsRule]"
                validate-on-blur
              />
            </v-card>

            <div
              class="mt-4"
              style="
                border-top: 2px solid gray;
                margin-top: 5px;
                justify-content: space-between;
                display: flex;
                padding-top: 15px;
              "
            >
              <v-btn
                color="error"
                :style="styleCustomFontSize"
                @click="dialog = false"
              >
                Cancel
              </v-btn>

              <v-btn
                color="primary"
                :loading="loading"
                :style="styleCustomFontSize"
                :disabled="
                  (!account || !cvv || !expiry || !zipcode) && !card && !loading
                "
                @click="checkStep"
              >
                Continue
              </v-btn>
            </div>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="3" v-if="invoice">
          <v-form v-model="valid" lazy-validation ref="card">
            <div class="mt-4">
              <v-row>
                <v-col
                  cols="12"
                  style="
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                  "
                >
                  <p class="mb-4" :style="styleCustomPrice">
                    {{ invoice.amount ? formatMoney(invoice.amount) : '$0.00' }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  style="
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                  "
                >
                  <p class="mb-4" :style="styleCustomText">
                    By clicking submit payment I authorize the amount
                    {{ invoice.amount ? formatMoney(invoice.amount) : '$0.00' }}
                    to be charged to my credit/debit card.
                  </p>
                </v-col>
              </v-row>
            </div>
            <div
              class="mt-4"
              style="border-top: 2px solid gray; margin-top: 5px"
            >
              <div
                class="mt-4"
                style="justify-content: space-between; display: flex"
              >
                <v-btn
                  color="error"
                  :style="styleCustomFontSize"
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>

                <v-btn
                  :loading="loading"
                  color="green darken-2 white--text"
                  @click="submitPayment"
                  :style="styleCustomFontSize"
                >
                  Submit Payment
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-container v-else>
      <v-card v-if="invoice">
        <v-toolbar dark color="primary" flat>
          <v-card-title>Manage Payment Methods</v-card-title>
          <v-spacer />
          <v-btn icon @click="dialog = !dialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form v-model="valid" lazy-validation ref="card">
          <v-card-actions>
            <!-- TODO, make the Select.vue component take in templates -->
            <v-select
              label="Choose Card"
              v-model="card"
              menu-props="auto"
              return-object
              prepend-icon="mdi-credit-card"
              :items="cards"
              :rules="cardRules"
            >
              <template v-slot:item="{ item, on }">
                <v-list-item v-on="on">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.description }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.account }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ item.expiry.substr(0, 2) }}/{{ item.expiry.substr(2) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                {{ item.description }} {{ item.account }}
              </template>
            </v-select>
          </v-card-actions>
          <v-card-actions>
            <!-- TODO, switch inputs to the custom input components made in ticket #6894 -->
            <v-text-field
              label="Amount"
              v-model="invoice.amount"
              prepend-icon="mdi-currency-usd"
              disabled
            />
          </v-card-actions>
        </v-form>
        <v-card-actions class="justify-center">
          <v-btn
            @click="submitPayment"
            :loading="loading"
            :style="styleCustomButton"
            color="primary"
          >
            Submit Payment
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { api, auth, messages } from '../../sharedPlugin';
import CardExpiration from '../CardExpiration.vue';
import NewMessageForm from '../messages/NewMessageForm.vue';
import device from '../../utils/device';
const Swal = require('sweetalert2');

export default {
  props: ['invoice', 'cards', 'invoices', 'showTransaction'],
  components: { CardExpiration, NewMessageForm },

  data() {
    return {
      maxWidth: device() === 'mobile' ? '100%' : '50%',
      heightCustom: device() === 'mobile' ? '100%' : '200px',
      styleCustomFontSizeText:
        device() === 'mobile'
          ? {
              'font-size': '4.5vw'
            }
          : {
              'font-size': '0.8vw'
            },
      styleCustomFontSize:
        device() === 'mobile'
          ? {
              'font-size': '3.0vw'
            }
          : {
              'font-size': '0.8vw'
            },
      styleCustomButton:
        device() === 'mobile'
          ? {
              height: '35px',
              'font-size': '3.0vw',
              width: '28%'
            }
          : null,
      styleCustomButtonMessage:
        device() === 'mobile'
          ? {
              height: '35px',
              'font-size': '3.0vw',
              width: '38%'
            }
          : null,
      styleCustomText:
        device() === 'mobile'
          ? {
              color: '#000000',
              'font-size': '4.5vw',
              'font-weight': '500'
            }
          : {
              color: '#000000',
              'font-size': '18px',
              'font-weight': '500'
            },
      styleCustomPrice:
        device() === 'mobile'
          ? {
              color: '#000000',
              'font-size': '9.0vw',
              'font-weight': '600'
            }
          : {
              color: '#000000',
              'font-size': '62px',
              'font-weight': '600'
            },

      color: '#1F6070',
      hiddenSelectCard: false,
      noGroup: false,
      newSendMessage: false,
      copyEmail: false,
      email: 'cryoservices@preludefertility.com',
      dialog: false,
      valid: false,
      loading: false,
      tos: true,
      card: null,
      e1: 1,
      cardRules: [(v) => !!v || 'Required'],
      account: null,
      cvv: null,
      expiry: '',
      zipcode: null,
      inputRule: [(v) => !!v || 'Input Required'],
      accountRule: [
        (v) =>
          (v && (v.length == 15 || v.length == 16)) ||
          'Card number should be 15-16 characters long'
      ],
      decimalRule: [
        (v) =>
          !isNaN(Number.parseFloat(v)) || 'Input must be a valid decimal value'
      ],
      cvvRule: [
        (v) =>
          (v && (v.length == 3 || v.length == 4)) || 'Must be 3 or 4 characters'
      ],
      onlyNumsRule: [(v) => (v && /^\d+$/.test(v)) || 'Only numbers allowed']
    };
  },
  computed: {
    ...messages.mapComputed(['messageGroups', 'messageGroupsLoading']),
    ...auth.mapComputed(['user']),
    checkMobile() {
      return device() === 'mobile' ? true : false;
    }
  },
  watch: {
    cards: function (val) {
      if (!this.card) {
        this.card = this.cards.find((card) => card.is_default);
        if (!this.card) {
          this.card = this.cards[0];
        }
      }
    },
    messageGroupsLoading: function (val) {
      if (!val) {
        if (this.messageGroups && this.messageGroups.length > 0) {
          this.color = '#1F6070';
        } else {
          this.color = 'gainsboro';
        }
      }
    }
  },
  methods: {
    ...messages.mapMethods(['pullMessageGroupsAsync']),
    closeMessage() {
      this.newSendMessage = false;
    },
    openTel() {
      window.open('tel:1-888-216-6538');
    },
    formatMoney(amount) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      if (!amount) return '$0.00';
      return formatter.format(amount);
    },
    newCreditCard() {
      this.card = null;
      this.hiddenSelectCard = true;
    },
    newMessage() {
      this.e1 = 1;
      this.newSendMessage = true;
    },
    copySucess() {
      this.copyEmail = true;
      this.$refs.clone.focus();
      document.execCommand('copy');

      setTimeout(() => {
        this.copyEmail = false;
      }, 3000);
    },
    checkStep() {
      if (this.card) {
        this.e1 = 3;
      } else if (this.account && this.cvv && this.expiry && this.zipcode) {
        this.addCard();
      }
    },
    submitPayment() {
      if (!this.$refs.card.validate()) return;

      this.loading = true;

      let config = {
        amount: parseFloat(this.invoice.amount),
        receipt: 'Y',
        capture: 'Y',
        tokenize: 'Y',
        ecomind: 'E',
        profile: this.card.profileid,
        expiry: this.card.expiry,
        account: this.card.token,
        accttype: this.card.description,
        invoice: this.invoice.invoice,
        userId: this.user.userId
      };

      let _invoices = [];

      if (this.invoices && this.invoices.length > 0) {
        this.invoices.forEach((item) => {
          _invoices.push({
            invoiceid: item.invoice,
            amount: parseFloat(item.balancedue)
          });
        });

        if (_invoices && _invoices.length > 0) {
          config.invoices = _invoices;
          config.invoice = null;
        }

        if (this.invoice.amount !== this.invoice.balancedue) {
          config.invoice = null;
        }
      }

      if (this.user) {
        config.name = this.user.fullName;
        config.email = this.user.email;
      }

      api.CardConnect.processCryoPayment(config)
        .then((result) => {
          if (result.transactionId && this.showTransaction) {
            this.$router.push({
              name: 'Invoice_Transaction',
              params: { id: result.transactionId }
            });
          }

          this.$emit('sucessPayment');

          Swal.fire({
            title: 'Payment Successful!',
            text: 'Please check your email on file or payment history section for a copy of your receipt.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ok'
          }).then((result) => {});
          this.dialog = false;
        })
        .catch((error) => {
          this.$emit('errorPayment');

          Swal.fire({
            title: 'Payment Failed',
            text:
              'Please update your payment method and try again, or contact our Cryo Team at cryoservices@preludefertility.com. ' +
              error,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ok'
          }).then((result) => {});

          this.dialog = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addCard() {
      this.loading = true;
      const apiCall = api.CardConnect.addCard;

      let isDefault = this.cards && this.cards.length > 0 ? 0 : 1;

      const _newCard = {
        account: this.account,
        cvv: this.cvv,
        expiry: this.expiry,
        zipcode: this.zipcode,
        is_default: isDefault
      };

      return apiCall(_newCard)
        .then((result) => {
          this.cards.push(result);
          this.card = result;

          this.e1 = 3;

          this.$store.commit('showSnackbar', {
            message: 'Card submitted',
            color: 'success'
          });
        })
        .catch((error) => {
          this.$store.commit('showSnackbar', {
            message: error,
            color: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    show() {
      this.pullMessageGroupsAsync();
      this.e1 = 1;
      this.account = null;
      this.cvv = null;
      this.expiry = '';
      this.zipcode = null;
      this.card = null;
      this.dialog = true;
      this.cardRules = [(v) => !!v || 'Required'];

      this.card = this.cards.find((card) => card.is_default);
      if (!this.card) {
        this.card = this.cards[0];
        this.default_card = this.cards[0];
      }
    }
  }
};
</script>
<style>
.button-submit {
  height: 80px !important;
  font-size: 1.6rem !important;
  color: black;
  border: 10px solid green;
  background-color: white !important;
  margin-top: 35px;
}
.footer {
  border-bottom-width: 1;
  border-bottom-color: black;
}
</style>
