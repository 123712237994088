<template>
  <v-card flat class="justify-space-between" height="100%">
    <v-card-title class="pa-0 pb-6" v-if="!isCryoMessage">
      <v-toolbar dark flat color="primary">
        <v-row align="center">
          <v-col class="text-left">
            <v-btn icon @click.stop="$emit('onBack')">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col>
            <v-toolbar-title class="text-center">New Message</v-toolbar-title>
          </v-col>
          <v-spacer />
          <v-col />
        </v-row>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent>
        <v-text-field
          label="Subject"
          auto-grow
          v-model="subject"
          outlined
          dense
        ></v-text-field>

        <v-select
          ref="select"
          v-if="messageGroups && messageGroups.length > 0"
          :loading="messageGroupsLoading"
          label="To"
          v-model="group"
          v-bind:items="messageGroups"
          item-text="name"
          item-value="id"
          menu-props="auto"
          outlined
          dense
        ></v-select>

        <v-textarea
          label="Message"
          v-model="message"
          outlined
          dense
        ></v-textarea>

        <v-alert type="error" v-if="error">
          {{ error }}
        </v-alert>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" outlined @click="discard">Discard</v-btn>
      <v-spacer />
      <v-btn color="primary" @click="createConversation" :loading="loading">
        Send
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { messages, auth, partner } from '../../sharedPlugin';

export default {
  props: ['isCryoMessage', 'groupId'],
  data: () => ({
    noGroup: false,
    error: null,
    data_model: []
  }),
  watch: {
    messageGroupsLoading: function (val) {
      if (!val && this.messageGroups.length === 0) {
        this.noGroup = true;
      }
    }
  },
  computed: {
    ...auth.mapComputed(['user']),
    ...messages.mapComputed([
      'messageGroups',
      'messageGroupsLoading',
      'messageTo',
      'loading'
    ]),
    ...partner.mapComputed(['getPartner']),
    subject: {
      get() {
        return this.messageTo.subject;
      },
      set(val) {
        this.setMessageTo({ ...this.messageTo, subject: val });
        this.error = null;
      }
    },
    group: {
      get() {
        if (this.groupId && this.messageGroups) {
          let _group = this.messageGroups.find((g) => g.id === this.groupId);
          if (!_group) {
            let groups = this.messageGroups;
            groups.push({ id: 234, name: 'Cryo Services Team' });
            this.$store.commit('setMessageGroups', groups);
          }

          this.setMessageTo({
            ...this.messageTo,
            groupId: this.groupId,
            groupName: 'Cryo Services Team'
          });
          return { id: 234, name: 'Cryo Services Team' };
        }
        return this.messageGroups.find((el) =>
          this.user.role === 'patient'
            ? el.id === this.messageTo.groupId
            : el.id === this.messageTo.patientId
        );
      },
      set(val) {
        this.user.role === 'patient'
          ? this.setMessageTo({ ...this.messageTo, groupId: val })
          : this.setMessageTo({ ...this.messageTo, patientId: val });

        this.error = null;
      }
    },
    message: {
      get() {
        return this.messageTo.message;
      },
      set(val) {
        let username = this.user?.fullName || null;

        if (
          this.getPartner &&
          this.getPartner.ownerName &&
          this.getPartner.name
        ) {
          let firtNameOwner = this.getPartner.ownerName.split(' ');
          let firtNamePartner = this.getPartner.name.split(' ');
          username = firtNameOwner[0] + ' on behalf of ' + firtNamePartner[0];
        }

        this.setMessageTo({ ...this.messageTo, message: val, username });
        this.error = null;
      }
    }
  },
  methods: {
    ...messages.mapMethods([
      'pullMessageGroupsAsync',
      'setMessageTo',
      'createConversationAsync'
    ]),
    createConversation() {
      this.createConversationAsync().then(() => {
        this.$emit('finished');
        this.subject = '';
        this.group = '';
        this.message = '';
      });
    },
    discard() {
      this.$emit('finished');
      this.subject = '';
      this.message = '';
      this.group = null;
    }
  },
  mounted() {
    this.pullMessageGroupsAsync();
    //this.$refs.select.lastItem = 200;
  }
};
</script>
<style scoped>
.v-select-list {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
<style scoped>
.v-card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.v-toolbar-title {
  align-self: center;
}

.v-card-actions {
  align-self: bottom;
}

.scrollCol {
  overflow: auto;
}
</style>
